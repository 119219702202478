import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`I veckans avsnitt snackar vi om nya Raspberry Pi, en grym uppdatering till Path of Exile, att Windows (äntligen!) får en ny terminal, och den nya versionen av scrcpy. Microsoft gästar våra Otrevligheter i två separata fall, och vi tipsar om en väldigt trevlig VPN-tjänst i Trevligheter.`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Raspberry Pi 4 med 8GB ram: `}<a parentName="li" {...{
          "href": "https://www.raspberrypi.org/blog/8gb-raspberry-pi-4-on-sale-now-at-75/"
        }}>{`https://www.raspberrypi.org/blog/8gb-raspberry-pi-4-on-sale-now-at-75/`}</a></li>
      <li parentName="ul">{`PoE + Vulkan = sant: `}<a parentName="li" {...{
          "href": "https://www.pathofexile.com/forum/view-thread/2866813"
        }}>{`https://www.pathofexile.com/forum/view-thread/2866813`}</a>{` `}</li>
      <li parentName="ul">{`Windows Terminal 1.0: `}<a parentName="li" {...{
          "href": "https://devblogs.microsoft.com/commandline/windows-terminal-1-0/"
        }}>{`https://devblogs.microsoft.com/commandline/windows-terminal-1-0/`}</a></li>
      <li parentName="ul">{`Nytt i senaste scrcpy: `}<a parentName="li" {...{
          "href": "https://www.omgubuntu.co.uk/2020/05/scrcpy-1-14-seamless-clipboard"
        }}>{`https://www.omgubuntu.co.uk/2020/05/scrcpy-1-14-seamless-clipboard`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Microsoft gör bort sig: `}<a parentName="li" {...{
          "href": "https://www.reddit.com/r/programming/comments/gqx6ta/the_day_appget_died/frvx641/?context=3"
        }}>{`https://www.reddit.com/r/programming/comments/gqx6ta/the_day_appget_died/frvx641/?context=3`}</a>{` `}<a parentName="li" {...{
          "href": "https://www.reddit.com/r/kde/comments/gmw6yu/name_clash_with_kde_maui_toolkit_by_microsoft/"
        }}>{`https://www.reddit.com/r/kde/comments/gmw6yu/name_clash_with_kde_maui_toolkit_by_microsoft/`}</a>{` `}</li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Mullvad VPN: `}<a parentName="li" {...{
          "href": "https://mullvad.net"
        }}>{`https://mullvad.net`}</a>{` `}</li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Alex PinePhone`}</h3>
    <ul>
      <li parentName="ul">{`Crust Deep-Sleep med Ubuntu Touch: `}<a parentName="li" {...{
          "href": "https://forum.pine64.org/showthread.php?tid=9957&pid=66462#pid66462"
        }}>{`https://forum.pine64.org/showthread.php?tid=9957&pid=66462#pid66462`}</a></li>
    </ul>
    <h3>{`Sebs Linuxäventyr`}</h3>
    <ul>
      <li parentName="ul">{`Seb vet inte hur man skriver till SD-kort`}</li>
    </ul>
    <h3>{`Vim`}</h3>
    <ul>
      <li parentName="ul">{`Officiellt över, och vi har en vinnare`}<ul parentName="li">
          <li parentName="ul">{`Sebs dotfiles: `}<a parentName="li" {...{
              "href": "https://github.com/sebastiangelotte/dotfiles"
            }}>{`https://github.com/sebastiangelotte/dotfiles`}</a></li>
        </ul></li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Jon Arvid tipsar om fler webbläsare (Brave, Chromium)`}</li>
      <li parentName="ul">{`Lämna gärna en snäll liten recension i era poddappar!`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0 (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      